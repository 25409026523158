import { CaterpillarDemo, FinishedDemo, Hexagon, Pentagon, Spiral1, Spiral2, Spiral3, Spiral4, Spiral5, SpiralDemo } from "../../../../src/posts/spiral-into-madness/components";
import * as React from 'react';
export default {
  CaterpillarDemo,
  FinishedDemo,
  Hexagon,
  Pentagon,
  Spiral1,
  Spiral2,
  Spiral3,
  Spiral4,
  Spiral5,
  SpiralDemo,
  React
};